.header_bg{
    background-color: white;
}
.header{
    width: 1200px;
    height: 80px;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 10px;
}
.nav{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #e9e9e9; */
}
.nav_item{
    height: 100%;
    width: 25%;
    background-color: rgb(30,144,255);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin: 5px;
    box-sizing: border-box;
    cursor: pointer;
    border: 1px solid #eee;
}
.nav_item2{
    height: 100%;
    width: 25%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    margin: 5px;
    box-sizing: border-box;
    cursor: pointer;
    border: 1px solid #eee;

}

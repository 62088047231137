.check_box{
    width: 100vw;
    min-height: 100vh;
    background-color: #eee;
    padding: 20px;
    box-sizing: border-box;
}
.check{
    width: 100%;
    height: 90vh;
    background-color: white;
    box-shadow: 0px 0px 1px 1px rgb(0,0,0,0.2);
    padding: 10px;
    box-sizing: border-box;
}
.check_top_class{
    font-size: 14px;
}
.check_top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nostudy{
    
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
    font-size: 30px;
}
.footer{
    background-color: rgb(0, 35, 75);
    color: white;
    padding: 0 0 15px 0;
}
.footer_box{
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    line-height:30px;
    padding-top: 15px;
    box-sizing: border-box;
}
.footer_t3{
    margin-right: 50px;
}
hr{
     margin: 15px 0;
}
.icp{
    width: 20px;
    height: 20px;
    margin-right: 20px;
}
.icp_box{
    display: flex;
    align-items: center;
}
.footer_ljie_item{
    cursor: pointer;
}
.footer_ljie_item:hover{
    color: red;
}
.bot_box{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.bot_box div{
    width: 33%;
}
.footer_bot{
    border-bottom: 2px solid white;
    display: inline-block;
    margin-bottom: 10px;
}
.footer_ljie{
   display: flex;
   justify-content: space-between;
}
a{
    text-decoration: none;
}
*{
    margin: 0;
    padding: 0;
}
.body{
    background-color: rgb(247,247,247);
    position: relative;
}



